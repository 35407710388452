<template>
    <div class="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed" style="background-image: url('/media/illustrations/sketchy-1/14.png')">
        <!--begin::Content-->
        <div class="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
            <!--begin::Logo-->
            <a href="/" class="mb-12">
                <img alt="Logo" src="/logo/virtaragroup-logo.png" class="h-30px"/>
            </a>
            <!--end::Logo-->

            <router-view></router-view>
        </div>
        <!--end::Content-->

        <!--begin::Footer-->
        <!--<div class="d-flex flex-center flex-column-auto p-10">
            <div class="d-flex align-items-center fw-bold fs-6">
                <a href="#" class="text-muted text-hover-primary px-2">About</a>

                <a href="#" class="text-muted text-hover-primary px-2">Contact</a>

                <a href="#" class="text-muted text-hover-primary px-2">Contact Us</a>
            </div>
        </div>-->
        <!--end::Footer-->
    </div>
</template>

<script>
import {Actions} from "@/store/enum/StoreEnums";

export default {
    name: "auth",
    components: {},
    mounted(){
        this.$store.dispatch(Actions.ADD_BODY_CLASSNAME, "bg-body");
    },
    unmounted() {
        this.$store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "bg-body");
    }
};
</script>
